import * as C from './pages/Home/Home.style';

import { BrowserRouter } from 'react-router-dom';
import Routes from './Routes';
const App = ()=>{
  return (

      <Routes />
    
  );
}

export default App