import React, {useState, useEffect} from 'react';
import Cookies from 'js-cookie';
import dayjs from 'dayjs';
import { CreateNewTaskTemplate, UploadFile } from '../../helpers/AxiosHandler';

import * as T from './AddTaskTemplate.style';
//worker: string, tasktitle: string, taskdescription: string, taskinit: Date, taskend: Date, taskattachment: string
const AddTask = (props: any) =>{

    const [addBy, setAddBy ] = useState<number>(0);
    const [taskTitle, setTaskTitle ] = useState('');
    const [taskDescription, setTaskDescription ] = useState('');
    const [taskAttachment, setTaskAttachment ] = useState(0);
    const [taskAttachmentPath, setTaskAttachmentPath ] = useState('');
    const [taskAttachmentPublicPath, setTaskAttachmentPublicPath ] = useState('');
    const [messageHandle, setMessageHandle ] = useState<string | undefined>('');
    const [uploadStatus, setUploadStatus ] = useState('');
    
    
    useEffect(()=>{
        if(Cookies.get('id')) setAddBy(Number(Cookies.get('id')))
    },[]);

 
    async function handleCreateTemplate(){
async function executeRequest(){
            const response = await CreateNewTaskTemplate(addBy, taskTitle, taskDescription, taskAttachment)
            if(response.error == false){
                setMessageHandle('Template adicionado com sucesso!');
            }else{
                setMessageHandle('Não foi possivel adicionar este template.')
            }
            setTimeout(()=>{
                setTaskTitle('');
                setTaskDescription('');
                setTaskAttachment(0);
                setMessageHandle('');
                setTaskAttachmentPublicPath('');
                setTaskAttachmentPath('');
                setUploadStatus('');
            },1500)
        }
        await executeRequest();
    }
    async function handleUploadFile(file: FileList, element: HTMLInputElement): Promise<void>{
        // setTaskAttachmentPath(e.target.value);
        const a = Array.from(file);
        // console.log(a[0])
        // const files = Array.from(e.target)
        const response = await UploadFile(a,addBy);
        if(response.error == false){
            setTaskAttachment(response.data.id);
            setTaskAttachmentPublicPath(response.data.public_path);
            setTaskAttachmentPath(element.value);
            setUploadStatus('Upload concluido!')
            // element.innerHTML = 'Upload concluido!'

        }else{
            setUploadStatus('Falha no upload!')
        }
        console.log(response)
    }

 return(
     <>
    <h1>Adicionar um novo template</h1>
    <hr />
    <T.TaskForm >
        <T.Input type="text" placeholder="Titulo" onChange={e=>setTaskTitle(e.target.value)} value={taskTitle}/>
        <T.Description placeholder="Descriçao da tarefa" rows={7} onChange={e=>setTaskDescription(e.target.value)} value={taskDescription}></T.Description>
        <T.MessageHandling>{uploadStatus}</T.MessageHandling>
        <T.Input type="file" onChange={e=>handleUploadFile(e.target.files as FileList, e.target)} value={taskAttachmentPath}/>
        <T.ButtonSuccess onClick={handleCreateTemplate}>Adicionar template</T.ButtonSuccess>
        <T.MessageHandling>{messageHandle}</T.MessageHandling>
    </T.TaskForm>
    </>
 )
}

export default AddTask;