import Cookies from 'js-cookie';



export const isLogged = ()=>{
     //checking 
     let auth = false;
     const token = Cookies.get('token');
     if(!token) return auth;
     if(token){
         const timestampNow = new Date().getTime();
         if(Math.round(Number(timestampNow) / 1000) < Number(Cookies.get('exp'))){
             auth = true;
         }else{ 
            Cookies.remove('token'); 
            Cookies.remove('id'); 
            Cookies.remove('is_admin'); 
            Cookies.remove('exp'); 
            auth = false;
         }
     }

     return auth;
}

export const logout = ()=>{
    Cookies.remove('token'); 
    Cookies.remove('id'); 
    Cookies.remove('is_admin'); 
    Cookies.remove('exp');
    window.location.href = '/';
}