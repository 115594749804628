import React, {useState, useEffect, ChangeEvent} from 'react';
import Cookies from 'js-cookie';
import dayjs from 'dayjs';
import {FaArchive, FaSearch} from 'react-icons/fa'
import { findTemplateByName, AddNewTask, GetAllUsersRef, UploadFile,GetAllUserTemplates, FindTemplateByTemplateId, FindFindUploadByUploadId, ArchieveTemplate } from '../../helpers/AxiosHandler';

import * as T from './AddTask.style';
//worker: string, tasktitle: string, taskdescription: string, taskinit: Date, taskend: Date, taskattachment: string
const AddTask = (props: any) =>{

    const [addBy, setAddBy ] = useState<number>(0);
    const [userRef, setUserRef ] = useState<number>(0);
    const [expectedToEnd, setExpectedToEnd ] = useState<string>(dayjs().add(1, 'day').format('YYYY-MM-DD'));
    const [taskTitle, setTaskTitle ] = useState('');
    const [taskDescription, setTaskDescription ] = useState('');
    const [taskAttachment, setTaskAttachment ] = useState(0);
    const [taskAttachmentPath, setTaskAttachmentPath ] = useState('');
    const [taskAttachmentPublicPath, setTaskAttachmentPublicPath ] = useState('');
    const [initialStatus, setInitialStatus ] = useState(0);
    const [messageHandle, setMessageHandle ] = useState<string | undefined>('');
    const [users, setUsers ] = useState([]);
    const [uploadStatus, setUploadStatus ] = useState('');
    const [taskTemplate, setTaskTemplate] = useState([]);
    const [taskTemplateSelected, setTaskTemplateSelected] = useState(0);
    const [hideUploadInput, setHideUploadInput] = useState(false);
    const [archiveTemplateStatus, setArchiveTemplateStatus] = useState('');
    const [templateSearchByName, setTemplateSearchByName] = useState('');
    
    
    useEffect(()=>{
        if(Cookies.get('id')) setAddBy(Number(Cookies.get('id')))
        async function execute(){
            const response = await GetAllUsersRef(Number(Cookies.get('id')));
            const items = response.map((property, key)=>
                <option key={key} value={property.id}>{property.name}</option>
            )
            

            const responseTemplate = await GetAllUserTemplates(Number(Cookies.get('id')));
            const templates = responseTemplate.data;
            const itemsTemplate = templates.map((property: {id: number, task_title: string}, key)=>
                <option key={key} value={property.id}>{property.task_title}</option>
            )
            setUsers(items as any);
            setTaskTemplate(itemsTemplate as any);
        }
        execute()

    },[]);

    async function handleSelectTemplate(e: ChangeEvent<HTMLSelectElement>){
        const elementValue = e.target.value;
        setTaskTemplateSelected(Number(elementValue));
        if(Number(elementValue) == 0){
            setTaskTitle('');
            setTaskDescription('');
            setTaskAttachment(0);
            setHideUploadInput(false);
        }

        const response = await FindTemplateByTemplateId(Number(elementValue));
        const templateData: any = response.data;
        setHideUploadInput(true);
        setTaskTitle(templateData.task_title);
        setTaskDescription(templateData.task_description);
        setTaskAttachment(templateData.task_attachment);
        const uploadInfoRequest = await FindFindUploadByUploadId(templateData.task_attachment);
        const uploadInfo: any = uploadInfoRequest.data;
        setUploadStatus(uploadInfo.public_path);
    }


    async function handleCreateNewTask(){
        async function executeRequest(){
            const response = await AddNewTask(addBy, userRef, dayjs(expectedToEnd).toISOString(), taskTitle, taskDescription, taskAttachment,0, (taskTemplateSelected)?true:false, (taskTemplateSelected)?taskTemplateSelected:0)
            if(response.error == false){
                setMessageHandle('Tarefa adicionada com sucesso!');
            }else{
                setMessageHandle('Não foi possivel adicionar a tarefa!')
            }
            setTimeout(()=>{
                setTaskTemplateSelected(0);
                setUserRef(0);
                setTaskTitle('');
                setTaskDescription('');
                setTaskAttachment(0);
                setMessageHandle('');
                setTaskAttachmentPublicPath('');
                setTaskAttachmentPath('');
                setUploadStatus('');
                setHideUploadInput(false);
            },750)
        }
        await executeRequest();
    }

    async function handleUploadFile(file: FileList, element: HTMLInputElement): Promise<void>{
        // setTaskAttachmentPath(e.target.value);
        setUploadStatus('Fazendo upload da imagem...')
        setHideUploadInput(true);
        const a = Array.from(file);
        // console.log(a[0])
        // const files = Array.from(e.target)
        const response = await UploadFile(a,addBy);
        if(response.error == false){
            setTaskAttachment(response.data.id);
            setTaskAttachmentPublicPath(response.data.public_path);
            setTaskAttachmentPath(element.value);
            setUploadStatus('Upload concluido!')
            setHideUploadInput(true);
        }else{
            setUploadStatus('Falha no upload!')
            setHideUploadInput(false);
        }
        // console.log(response)
    }
    async function handleArchiveTemplate(){
        setArchiveTemplateStatus('Arquivando template...');
        const response = await ArchieveTemplate(true, taskTemplateSelected, addBy);
        if(response.error == true){
            return setArchiveTemplateStatus('Erro ao arquivar este template...');
        }
        setArchiveTemplateStatus('Template arquivado com sucesso. Essa ação não poderá ser desfeita!');
        setTaskTemplateSelected(0);
        setTaskTitle('');
        setTaskDescription('');
        setTaskAttachment(0);
        setMessageHandle('');
        setTaskAttachmentPublicPath('');
        setTaskAttachmentPath('');
        setUploadStatus('');
        setHideUploadInput(false);
        setTimeout(()=>{setArchiveTemplateStatus('');},3000);
    }

    async function findTemplateByNameHandler(name =''){
        setTemplateSearchByName(name);
        if(name === ''){
            const responseTemplate = await GetAllUserTemplates(Number(Cookies.get('id')));
            const templates = responseTemplate.data;
            const itemsTemplate = templates.map((property: {id: number, task_title: string}, key)=>
                <option key={key} value={property.id}>{property.task_title}</option>
            )
            setTaskTemplate(itemsTemplate as any);
        }
        async function executeRequest(){
            const response = await findTemplateByName(name, addBy);
            if(response.error) return alert('invalid data');
            const itemsTemplate = response.data.map((property: {id: number, task_title: string}, key:any)=>
                <option key={key} value={property.id}>{property.task_title}</option>
            )
            setTaskTemplate(itemsTemplate as any);
        }
        await executeRequest();
    }

 return(
     <>
    <h1>Adicionar uma nova tarefa</h1>
    <hr />
    <T.TaskForm >
        <small>Você quer usar um template para esta tarefa ?</small>

        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '10px'}}>
        <T.Input type="text" placeholder="Find by name" onChange={e=>findTemplateByNameHandler(String(e.target.value))} value={templateSearchByName}/>
        <T.SelectStyled onChange={handleSelectTemplate} value={taskTemplateSelected}>
            <option value={0}>Não quero usar template</option>
            {taskTemplate}
        </T.SelectStyled>
        <FaArchive title='Clique para arquivar o template' style={(taskTemplateSelected)? {display: 'block', cursor: 'pointer'}: {display: 'none'}} color="#d63031" onClick={handleArchiveTemplate}></FaArchive>
        </div>
        <T.MessageHandling>{archiveTemplateStatus}</T.MessageHandling>
        <T.Input type="text" placeholder="Titulo" onChange={e=>setTaskTitle(e.target.value)} value={taskTitle}/>
        <T.Description placeholder="Descriçao da tarefa" rows={7} onChange={e=>setTaskDescription(e.target.value)} value={taskDescription}></T.Description>
        <T.MessageHandling>{uploadStatus}</T.MessageHandling>
        <T.Input type="file" onChange={e=>handleUploadFile(e.target.files as FileList, e.target)} value={taskAttachmentPath} style={{display: hideUploadInput?'none':'block', height: '25px', cursor: 'pointer'}}/>
        <label htmlFor="selectUserRef">Selecione o usuário destino</label>
        <T.SelectStyled id="selectUserRef" onChange={e=>setUserRef(Number(e.target.value))} value={userRef} >
            <option value={0}>Selecione um usuário</option>
            {users}
        </T.SelectStyled>
        
        <T.Input type="date" onChange={e=>setExpectedToEnd(e.target.value)} defaultValue={expectedToEnd}/>
        <T.ButtonSuccess onClick={handleCreateNewTask}>Adicionar tarefa</T.ButtonSuccess>
        <T.MessageHandling>{messageHandle}</T.MessageHandling>
    </T.TaskForm>
    </>
 )
}

export default AddTask;