import styles from 'styled-components';

export const Container = styles.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;
export const LoginBox = styles.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 30px;`;
export const Title = styles.h3``;
export const MailArea = styles.input`
    border: 1px solid #CCC;
    width: 210px;
    height: 20px;
    margin: 10px;
    padding: 5px;  
    outline: none;   
`;
export const PasswordArea = styles.input`
border: 1px solid #CCC;
width: 210px;
height: 20px;
margin: 10px;
padding: 5px;
outline: none;     `;
export const SubmitButton = styles.button`

width: 210px;
height: 20px;
background-color: #4b7bec;
border: none;
color: #fff;

&:hover{
    background-color: #3867d6;
    cursor: pointer;
}

border-radius: 3px;


`;

export const SmallText = styles.small`

`

export const ErrorMessage = styles.span`

font-weight: 600;
color: #eb3b5a;
font-size: 13px;

`

