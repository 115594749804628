import React, {useState, useEffect } from 'react';
import Modal from 'react-modal';
import dayjs from 'dayjs';
import {InitTask, EndTask ,FindFindUploadByUploadId} from '../../helpers/AxiosHandler'


import * as T from './Table.style';
//worker: string, tasktitle: string, taskdescription: string, taskinit: Date, taskend: Date, taskattachment: string

const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };





const TaskItemUser = (props: any) =>{
    const [openHandleTaskModal, setOpenHandleTaskModal] = useState(false);
    const [startedTask, setStartedTask] = useState(false);
    const [displayTaskDetail, setDisplayTaskDetail] = useState(false);
    const [attachmentPublicPath, setAttachmentPublicPath] = useState('');
    const [statusDescribe, setStatusDescribe] = useState('');
    const [statusDescribeColor, setStatusDescribeColor] = useState('');
    const [taskStatusToSend, setTaskStatusToSend] = useState(0);
    const [displayTextAreaForRelatory, setDisplayTextAreaForRelatory] = useState(false);
    const [taskReportText, setTaskReportText] = useState('');
    const [errorHandling, setErrorHandling] = useState('');
    const [errorHandlingColor, setErrorHandlingColor] = useState('');


    useEffect(()=>{
        switch (props.status){
            case 0:
                setStatusDescribe('Não iniciada');
                setStatusDescribeColor('#000');
                break;
            case 1:
                setStatusDescribe('Iniciada');
                setStatusDescribeColor('#20bf6b');
                break;
            case 2:
                setStatusDescribe('Concluída sem problemas');
                setStatusDescribeColor('#20bf6b');
                break;
            case 3:
                setStatusDescribe('Concluída com problemas');
                setStatusDescribeColor('#eb3b5a');
                break;
            case 4: 
                setStatusDescribe('Não foi possivel concluir');
                setStatusDescribeColor('#eb3b5a');
                break;
            default:
                setStatusDescribe('Não concluida');
                setStatusDescribeColor('#eb3b5a');
                break;
            
        }
    },[])

    useEffect(()=>{
      if(taskStatusToSend >= 3){
        setDisplayTextAreaForRelatory(true)
      }else{
        setDisplayTextAreaForRelatory(false);
      }
    },[taskStatusToSend])

  function openModal_HandleTask() {
    setOpenHandleTaskModal(true);
  }
  function afterOpenModal_HandleTask() {
    displayTaskAttachmentPublicPath(props.taskattachment);
    
  }
  function closeModal_HandleTask() {
    setOpenHandleTaskModal(false);
    props.updateTable();
  }


  async function handleStartTask(e: any){
    const response = await InitTask(props.uid, props.id);
    if(response.error ==false){
        setStartedTask(true);
        setDisplayTaskDetail(true);
    }
    
  }


  async function displayTaskAttachmentPublicPath(uploadId: number){
    const response: any = await FindFindUploadByUploadId(uploadId);
    setAttachmentPublicPath(response.data.public_path);
}

async function handleConcludedTask(){
    if(taskStatusToSend < 2){
        setErrorHandlingColor('#eb3b5a')
        return setErrorHandling('Selecione uma opção de conclusão para continuar');
    }
    if(taskStatusToSend >= 3 && taskReportText.length < 30){
        setErrorHandlingColor('#eb3b5a')
        return setErrorHandling('O report da tarefa deve conter no mínimo 30 caracteres, por favor, detalhe mais.');
    }
    setErrorHandling('');
    setErrorHandlingColor('#000')
    if(taskStatusToSend == 2){
      setTaskReportText('');
    }
    const response = await EndTask(props.uid, props.id, taskStatusToSend, taskReportText, props.tasktemplate, props.tasktemplateid);
    if(response.error === false){
        setErrorHandlingColor('#20bf6b')
        setErrorHandling('Tarefa concluida com sucesso!')
        setTimeout(()=>{
            closeModal_HandleTask();
            props.updateTable();
        },1000)
    }else{
        setErrorHandlingColor('#eb3b5a')
        setErrorHandling('Houve algum erro interno no servidor ao concluir a tarefa.')
    }

}

 return(
    <T.TableLine>
        {/* <T.TableContent scope='row'>{props.worker}</T.TableContent> */}
        {/* <T.TableContent>{props.tasktitle}</T.TableContent> */}
        <T.TableContent data-id={props.id} onClick={openModal_HandleTask} style={{cursor: 'pointer'}}><strong>Clique para detalhes</strong></T.TableContent>
        <T.TableContent>{props.taskinit?dayjs(props.taskinit).format('DD/MM/YYYY HH:mm:ss'):''}</T.TableContent>
        <T.TableContent>{props.taskend?dayjs(props.taskend).format('DD/MM/YYYY HH:mm:ss'):''}</T.TableContent>
        <T.TableContent style={{color: statusDescribeColor}}>{statusDescribe}</T.TableContent>
        {/* <T.TableContent>{props.taskattachment}</T.TableContent> */}
        
        <Modal
        isOpen={openHandleTaskModal}
        onAfterOpen={afterOpenModal_HandleTask}
        onRequestClose={closeModal_HandleTask}
        style={customStyles}
        ariaHideApp={false}
        contentLabel="Add new task"
      >
        <T.TaskDetailBox style={{display: startedTask?'block':'none'}}>
        {props.status > 1 
            ? <h3 style={{color: '#20bf6b'}}>Esta tarefa já foi concluída, portanto não pode mais ser vista.</h3>
            
            :<>
            <p>Descrição do post</p>
            <T.Description rows={7} readOnly={true} style={{border:"1px solid #DDD"}}>{props.taskdescription}</T.Description>

            <small><a href={attachmentPublicPath} target={'_blank'} style={{textDecoration: 'none'}}>Clique para ir até a imagem</a></small>
            <hr />
            <select name="taskStatus" id="taskStatus" style={{marginTop: "15px", width: "100%", height:"25px", border: "1px solid #DDD", outline: "none"}} onChange={e => setTaskStatusToSend(Number(e.target.value))}>
                <option value="0" selected={true}>Selecione uma opção de conclusão</option>
                <option value="2">Concluída sem problemas</option>
                <option value="3">Concluída com problemas</option>
                <option value="4">Não foi possivel concluir</option>
            </select>
            <T.Description rows={7} style={{display: displayTextAreaForRelatory? 'block':'none', marginTop: "15px", padding:"10px"}} placeholder="Relatório sobre a tarefa é obrigatorio e deve conter pelo menos 30 caractéres" onChange={e=>setTaskReportText(e.target.value)} value={taskReportText}></T.Description>
            <small style={{ color: errorHandlingColor }}>{errorHandling}</small>
            <T.ButtonWhite onClick={handleConcludedTask} style={{marginTop: "15px"}}>Concluir Tarefa</T.ButtonWhite>
            <br />
            </>
            
        }
        </T.TaskDetailBox>
        {props.status >= 1?
        <T.TaskDetailBox style={{display: startedTask?'none':'block'}}>
            {props.status > 1 
            ? <h3 style={{color: '#20bf6b'}}>Esta tarefa já foi concluída, portanto não pode mais ser vista.</h3>
            
            :<>
            <p>Descrição do post</p>
            <T.Description rows={7} readOnly={true} style={{border:"1px solid #DDD"}}>{props.taskdescription}</T.Description>

            <small><a href={attachmentPublicPath} target={'_blank'} style={{textDecoration: 'none'}}>Clique para ir até a imagem</a></small>
            <hr />
            <select name="taskStatus" id="taskStatus" style={{marginTop: "15px", width: "100%", height:"25px", border: "1px solid #DDD", outline: "none"}} onChange={e => setTaskStatusToSend(Number(e.target.value))}>
                <option value="0" selected={true}>Selecione uma opção de conclusão</option>
                <option value="2">Concluída sem problemas</option>
                <option value="3">Concluída com problemas</option>
                <option value="4">Não foi possivel concluir</option>
            </select>
            <T.Description rows={7} style={{display: displayTextAreaForRelatory? 'block':'none', marginTop: "15px", padding:"10px"}} placeholder="Relatório sobre a tarefa é obrigatorio e deve conter pelo menos 30 caractéres" onChange={e=>setTaskReportText(e.target.value)} value={taskReportText}></T.Description>
            <small style={{ color: errorHandlingColor }}>{errorHandling}</small>
            <T.ButtonWhite onClick={handleConcludedTask} style={{marginTop: "15px"}}>Concluir Tarefa</T.ButtonWhite>
            <br />
            </>
            
        }
        </T.TaskDetailBox>
        :<T.ButtonSuccess onClick={handleStartTask} style={{display: startedTask?'none':'block'}}>Iniciar tarefa</T.ButtonSuccess>
        }
      </Modal>
    </T.TableLine>
 )
}

export default TaskItemUser;