import React,{useState, useEffect} from 'react';
import Cookies from 'js-cookie';
import {GetAllUsersRef} from '../../helpers/AxiosHandler';
import dayjs from 'dayjs';
import * as D from './DetailSearch.style';


const DetailSearch = (props: any)=>{

    const [selectedDate, setSelectedDate] = useState(dayjs().format('YYYY-MM-DD'));
    const [selectedUser, setSelectedUser] = useState(0);
    const [allUsersRef, setAllUsersRef] = useState([]);
    const [errorHandler, setErrorHandler] = useState('');
    
    useEffect(()=>{

        async function exec(){
            const is_admin = Number(Cookies.get('is_admin'));
            const id = Number(Cookies.get('id'));
            const response = await GetAllUsersRef(id);
            const items = response.map((property, key)=><option key={key} value={property.id}>{property.name}</option>);
            setAllUsersRef(items as any);
        }
        exec();
    },[])

    useEffect(()=>{
        async function exec(){
            const formatted = dayjs(selectedDate).format('YYYY-MM-DD');
            if(selectedUser == 0){
                return await props.updateTable(formatted, 0);
            }
            await props.updateTable(formatted, selectedUser);
            setErrorHandler('')
        }
        exec();
        
    },[selectedDate,selectedUser])



    if(props.display){
    return (
        <D.SearchArea>
            <D.DateInput type='date' onChange={e=>setSelectedDate(e.target.value)} value={selectedDate}/>
            <D.Select onChange={e=>setSelectedUser(Number(e.target.value))} value={selectedUser}>
                <option value="0" defaultChecked={true}>Todos os usuários</option>
                {allUsersRef}
            </D.Select>
            <small>{errorHandler}</small>
        </D.SearchArea>
    )
    }else{
        return (<></>)
    }
}

export default DetailSearch;