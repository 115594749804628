
import axios from 'axios';
import cookies from 'js-cookie';


const axiosInstance = axios.create({
    baseURL: 'https://taskscheduler-adspub.herokuapp.com/'
    // baseURL: 'http://localhost:3535/'
})

console.log(cookies.get('token'))
if(cookies.get('token')){
    axiosInstance.defaults.headers.common['Authorization'] = cookies.get('token') as string;
}
axiosInstance.defaults.headers.common['Content-Type'] = 'application/json';


export const AxiosLoginAPI = async (mail: string, pass: string)=>{
    cookies.remove('token') 
    cookies.remove('id')  
    cookies.remove('is_admin')  
    cookies.remove('exp')  
    const axisoResponse = await axiosInstance.post('/user/authenticate',{mail: mail, pass: pass}).then(async function (response) {
        if(response.data.login == true){
            cookies.set('token', response.data.jwt, {expires: 7});
            console.log(response.data.payload)
            cookies.set('id', response.data.payload.id, {expires: 7});
            cookies.set('is_admin', response.data.payload.isAdmin, {expires: 7});
            cookies.set('exp', response.data.payload.exp, {expires: 7});
        }
        return response.data;
      }).catch(function (error) {
        return error.response.data;
      });
      return axisoResponse;
}

export const AxiosValidateToken = async (jwt: string)=>{
    const axiosResponse = axiosInstance.get('/user/verifyjwt/'+jwt)
    .then(response =>{
        console.log(response.data)
        return response.data;
    })
    return axiosResponse;
}

export const GetAllTasksNoParams = async()=>{
    const axiosResponse = await axiosInstance.get('/task/find/all')
    .then(response=>{
        return response.data;
    })
    return axiosResponse;
}

export const FindAllTasksByUserIdAndDate = async(date: string, id: number)=>{
    const axiosResponse = await axiosInstance.post('/task/find/date',{
        date,
        id
    })
    .then(response=>{
        return response.data;
    })
    return axiosResponse;
}

export const FindAllTasksAddedByMeByDate = async(date: string, id: number)=>{
    const axiosResponse = await axiosInstance.post('/task/find/all/addbyme/bydate',{
        date,
        id
    })
    .then(response=>{
        return response.data;
    })
    return axiosResponse;
}

export const GetAllUsersRef = async (admin_ref: number): Promise<any[]> =>{
    const axiosResponse = await axiosInstance.get(`/user/findbyadminref/${admin_ref}`)
    .then(response =>{
        return response.data;
    })
    return axiosResponse;
}

export const AddNewTask = async (addBy: number, userRef: number, expectedToEnd: string, taskTitle: string, taskDescription: string, taskAttachment: number = 0, taskStatus: number = 0, task_template: Boolean = false, task_template_id: Number = 0)=>{
    const axiosResponse = await axiosInstance.post('/task/create',
    {
        add_by: addBy,
        user_ref: userRef,
        expected_to_end: expectedToEnd,
        task_title: taskTitle,
        task_description: taskDescription,
        task_attachment: taskAttachment,
        task_status: taskStatus,
        task_template,
        task_template_id

    })
    .then(response=>{
        return response.data;
    })
    return axiosResponse
}

export const InitTask = async (uid: number, task_id: number)=>{
    const axiosResponse = await axiosInstance.put('/task/update/init',
    {
        uid,
        task_id
    })
    .then(response=>{
        return response.data;
    })
    return axiosResponse
}
export const EndTask = async (uid: number, task_id: number, status: number, task_report: string = '', task_template: Boolean = false, task_template_id: Number = 0)=>{
    const axiosResponse = await axiosInstance.put('/task/update/end',
    {
        uid,
        task_id,
        status,
        task_report,
        task_template_id
    })
    .then(response=>{
        return response.data;
    })
    return axiosResponse
}

export const DeleteTask = async (id: number)=>{
    const axiosResponse = await axiosInstance.delete('/task/delete',
    {
        data:{
            id
        }
    })
    .then(response=>{
        return response.data;
    })
    return axiosResponse
}

export const AddNewUser = async (name: string, email: string, password: string, admin_ref: number, is_admin: number = 0)=>{
    const axiosResponse = await axiosInstance.post('/user/create',
    {
        name,
        email,
        password,
        admin_ref,
        is_admin
    })
    .then(response=>{
        return response.data;
    })
    return axiosResponse
}

export const DestroyUser = async (email: string, uid: number)=>{
    const axiosResponse = await axiosInstance.delete('/user/delete',
    {
       data:{
           email,
           uid
       }
    })
    .then(response=>{
        return response.data;
    })
    .catch(errorResponse =>{
        if(errorResponse.response){
            return errorResponse.response.data;
        }
    })
    return axiosResponse
}

export const UploadFile = async(filePath: any[], uploaderId: number)=>{
    const form = new FormData();
    form.append("image_file", filePath[0]);
    form.append("id", String(uploaderId));

    axiosInstance.defaults.headers.common['Content-Type'] = 'multipart/form-data';
    const axiosResponse = await axiosInstance.post(`/uploadfile`,form,{
        headers:{
            'Content-Type': 'multipart/form-data',
        }
    })
    .then(response =>{
        return response.data;
    })
    .catch(err=>{
        // console.log(err.response.data)
        return err.response.data;
    })
    return axiosResponse;
}

export const CreateNewTaskTemplate = async (addBy: number, taskTitle: string, taskDescription: string, taskAttachment: number)=>{
    const axiosResponse = await axiosInstance.post('/tasktemplate/create',
    {
        add_by: addBy,
        task_title: taskTitle,
        task_description: taskDescription,
        task_attachment: taskAttachment,
    })
    .then(response=>{
        return response.data;
    })
    return axiosResponse
}

interface getUserTemplatesType {
    data: [],
    error: boolean
}

interface getUploadInfo {
    data: [],
    error: boolean
}

export const GetAllUserTemplates = async(userId: number): Promise<getUserTemplatesType> =>{
    const axiosResponse = await axiosInstance.get(`/tasktemplate/list/${userId}`)
    .then(response =>{
        return response.data;
    })
    return axiosResponse;
}
export const FindTemplateByTemplateId = async(templateId: number): Promise<getUserTemplatesType> =>{
    const axiosResponse = await axiosInstance.get(`/tasktemplate/${templateId}`)
    .then(response =>{
        return response.data;
    })
    return axiosResponse;
}
export const ArchieveTemplate = async (archive: Boolean = true, template_id: number, uid: number ): Promise<getUserTemplatesType> =>{
    const axiosResponse = await axiosInstance.post(`/tasktemplate/archive`,{
        archive,
        template_id,
        uid
    })
    .then(response =>{
        return response.data;
    })
    .catch(error=>{
        return error.response.data;
    })
    return axiosResponse;
}

export const FindFindUploadByUploadId = async(uploadId: number): Promise<getUploadInfo>=>{
    const axiosResponse = await axiosInstance.get(`/uploadfile/find/${uploadId}`)
    .then(response =>{
        return response.data;
    })
    return axiosResponse;
}

export const FindUserDetailById = async (uid: number)=>{
    const axiosResponse = await axiosInstance.get(`/user/findbyid/${uid}`)
    .then(response =>{
        return response.data;
    })
    return axiosResponse;
}

export const findTemplateByName = async (task_title: string, uid: number)=>{
    const axiosResponse = await axiosInstance.post(`/tasktemplate/findbyname`,{
        task_title,
        uid
    })
    .then(response =>{
        return response.data;
    })
    return axiosResponse;
}


export const DestroyTaskById = async ()=>{

}

export const FindTaskById = async ()=>{

}

export const FindTaskByUserId = async ()=>{

}

export const FindTaskByUserIdAndDate = async ()=>{

}

export const FindTaskByUserIdAndDateInterval = async ()=>{

}


