import React, {useState} from 'react';

import {Routes, Route, BrowserRouter} from 'react-router-dom';

import {isLogged} from './helpers/AuthHandler'



import Home from './pages/Home/index';
import Login from './pages/Login/index';

import {RequireAuth} from './components/RequireAuth';

export default ()=>{
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<RequireAuth><Home /></RequireAuth>}/>
                {isLogged() == false &&
                <Route path="/login" element={<Login />}/>
                }
                <Route path="*" element={<Login />}/>
            </Routes>
        </BrowserRouter>
    );
}