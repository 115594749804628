import styled from 'styled-components';

export const SearchArea = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 35px;
`

export const DateInput = styled.input`
    width: 220px;
    height: 30px;
    border: 1px solid #ddd;
    margin: 20px;
    font-family: Arial, Helvetica, sans-serif;
`

export const Select = styled.select`
    width: 220px;
    height: 30px;
    border: 1px solid #ddd;
    margin: 20px;
    font-family: Arial, Helvetica, sans-serif;
`

