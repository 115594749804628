import React, {useEffect, useState} from 'react';

import * as C from './Login.style';

import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';


import { AxiosLoginAPI, AxiosValidateToken } from '../../helpers/AxiosHandler';


const Page = ()=>{
    const navigate = useNavigate();

    const [mail, setMail] = useState('');
    const [pass, setPass] = useState('');
    const [disabled, setDisabled] = useState(false);
    const [loadingStatus, setLoadingStatus] = useState('Efetuar login');
    const [errorMessage, setErrorMessage] = useState('');

    const handleSubmit = async ()=>{
        setDisabled(true);
        setLoadingStatus('Efetuando login...');
        if(mail == '' || pass == '') { 
            setLoadingStatus('Tentar novamente');
            setDisabled(false);
            return setErrorMessage('Por favor preencha usuario e senha')
        };
        const requestLoginResponse = await AxiosLoginAPI(mail, pass);
        if(!requestLoginResponse.login || requestLoginResponse.error){
            setDisabled(false);
            setLoadingStatus('Erro ao efetuar login');
            setErrorMessage(requestLoginResponse.message);
        }else if(requestLoginResponse.login == true){
            window.location.href = '/';
        }else{
            setDisabled(false);
            setLoadingStatus('Erro ao efetuar login');
        }

        setDisabled(false);
        setInterval(()=>{
            setLoadingStatus('Efetuar login');
        },4000);
    }
    async function handleKeyPress(event: any){
        if(event.keyCode == 13){
            handleSubmit();
        }
    }

    return (
        <C.Container>
            <C.LoginBox>
                
                <C.Title>Faça login para continuar</C.Title>
                <C.MailArea type="email" disabled={disabled} value={mail} onChange={e=>setMail(e.target.value)} onKeyUp={handleKeyPress}></C.MailArea>
                <C.PasswordArea type="password" disabled={disabled} value={pass} onChange={e=>setPass(e.target.value)} onKeyUp={handleKeyPress} ></C.PasswordArea>
                <C.SubmitButton onClick={handleSubmit} disabled={disabled}><strong>{loadingStatus}</strong></C.SubmitButton>
                <C.ErrorMessage>{errorMessage}</C.ErrorMessage>
            </C.LoginBox>
            
            <C.SmallText>Powered by ADS PUBLICIDADE - 2022</C.SmallText>
        </C.Container>
    );
}

export default Page