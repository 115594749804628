import * as C from './Home.style';
import React, {useState} from 'react';
import dayjs from 'dayjs';
import Modal from 'react-modal';
import { useNavigate, Link } from 'react-router-dom'
import { isLogged , logout} from '../../helpers/AuthHandler'
import {GetAllTasksNoParams, FindAllTasksByUserIdAndDate, FindAllTasksAddedByMeByDate} from '../../helpers/AxiosHandler';
import {FaSearch} from 'react-icons/fa'

import Cookies from 'js-cookie';
import TaskItem from '../../components/TaskTable/TableLine';
import TaskItemUser from '../../components/TaskTable/TableLineUser';
import AddTask from '../../components/AddTask';
import AddTaskTemplate from '../../components/AddTaskTemplate';
import AddNewUser from '../../components/AddNewUser';
import DeleteUser from '../../components/DeleteUser';
import DetailSearch from '../../components/DetailSearch';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

const Page = ()=>{
    const [tasks, setTasks] = useState<any[]>([]);  
    const [isAdmin, setIsAdmin] = useState(0);
    const [taskTableItems, setTaskTableItems] = useState<any[]>([]);  
    const [loading, setLoading] = useState(true);  
    const [userInformations, setUserInformations] = useState(true);  
  
  const navigate = useNavigate();
    React.useEffect(()=>{
        const logged = isLogged();
        if(!logged){ navigate('/login') }
    },[])

    React.useEffect(()=>{
      getTasksToDisplay();
    },[])
    
    //Displays tasks for users / display for admins
    async function getTasksToDisplay(day: string = dayjs().format('YYYY-MM-DD')) {
      setLoading(true);
      setTaskTableItems([]);
      setIsAdmin(Number(Cookies.get('is_admin')));
      const is_admin = Number(Cookies.get('is_admin'));
      const id = Number(Cookies.get('id'));
      if(is_admin){
        const response = await FindAllTasksAddedByMeByDate(day, id);
        let items: any = [];
        let userInfo: any = [];
        response.data.map((prop: any, key: any)=>{
          items.push(<TaskItem 
            key={key} 
            taskreport={prop.task_report} 
            expecttoend={prop.expected_to_end}
            uid={Number(Cookies.get('id'))} 
            id={prop.id} 
            status={prop.task_status} 
            worker={prop.user_ref} 
            tasktitle={prop.task_title} 
            taskdescription={prop.task_description} 
            taskinit={prop.task_init} 
            taskend={prop.task_end} 
            tasktemplate={prop.task_template}
            tasktemplateid={prop.task_template_id}
            taskattachment={prop.task_attachment}
            />)
        });
        response.usersTaskInformations.map((prop:any, key: any)=>{
          userInfo.push(
            <C.UserInformationsBox>
            <C.UserInformationsTitle>
              {prop.name}
            </C.UserInformationsTitle>
            <C.UserInformationsContent>
              <C.UserInformationsItem>{prop.total}</C.UserInformationsItem>
              <C.UserInformationsItem style={{color:'#00b894'}}>{prop.concludedWithSuccess}</C.UserInformationsItem>
              <C.UserInformationsItem style={{color:'#d63031'}}>{prop.concludedWithProblem + prop.concludedWithProblemAnything}</C.UserInformationsItem>
              <C.UserInformationsItem style={{color:'#e17055'}}>{prop.total - prop.concludedWithSuccess - prop.concludedWithProblem - prop.concludedWithProblemAnything}</C.UserInformationsItem>
            </C.UserInformationsContent>
          </C.UserInformationsBox>
          )
        })
        setTaskTableItems(items);
        setUserInformations(userInfo)
        setLoading(false);
      }else{
        const response = await FindAllTasksByUserIdAndDate(day, id);
        let items: any = [];
        response.data.map((prop: any, key: any)=>{
          items.push(<TaskItemUser 
            key={key} 
            expecttoend={prop.expected_to_end} 
            uid={Number(Cookies.get('id'))} 
            id={prop.id} 
            status={prop.task_status} 
            worker={prop.user_ref} 
            tasktitle={prop.task_title} 
            taskdescription={prop.task_description} 
            taskinit={prop.task_init} 
            taskend={prop.task_end} 
            taskattachment={prop.task_attachment}
            tasktemplate={prop.task_template}
            tasktemplateid={prop.task_template_id}
            updateTable={getTasksToDisplay}/>)   
        });
        setTaskTableItems(items);
        setLoading(false);
      }
    }

    async function updateTableWithSearchParams(day: string, user: number){
      setLoading(true);
      setTaskTableItems([]);
      setIsAdmin(Number(Cookies.get('is_admin')));
      const is_admin = Number(Cookies.get('is_admin'));
      const id = Number(Cookies.get('id'));
      if(user == 0 && is_admin){
        const response = await FindAllTasksAddedByMeByDate(day, id);
        let items: any = [];
        response.data.map((prop: any, key: any)=>{
          items.push(<TaskItem 
            key={key} 
            taskreport={prop.task_report}
            expecttoend={prop.expected_to_end}
            uid={Number(Cookies.get('id'))} 
            id={prop.id} 
            status={prop.task_status} 
            worker={prop.user_ref} 
            tasktitle={prop.task_title} 
            taskdescription={prop.task_description} 
            taskinit={prop.task_init} 
            taskend={prop.task_end} 
            taskattachment={prop.task_attachment}
            updateTable={getTasksToDisplay}
            />)   
        });
        setTaskTableItems(items);
        setLoading(false);
      }else if (user != 0 && is_admin){
        const response = await FindAllTasksByUserIdAndDate(day, user);
        let items: any = [];
        response.data.map((prop: any, key: any)=>{
          items.push(<TaskItem 
            key={key} 
            taskreport={prop.task_report}
            expecttoend={prop.expected_to_end}
            uid={Number(Cookies.get('id'))} 
            id={prop.id} 
            status={prop.task_status} 
            worker={prop.user_ref} 
            tasktitle={prop.task_title} 
            taskdescription={prop.task_description} 
            taskinit={prop.task_init} 
            taskend={prop.task_end} 
            taskattachment={prop.task_attachment}
            updateTable={getTasksToDisplay}
            />)   
        });
        setTaskTableItems(items);
        setLoading(false);
      }
    }

    const [openAddTaskModal, setOpenAddTaskModal] = React.useState(false);

    function openModal_AddTask() {
      setOpenAddTaskModal(true);
    }
    function afterOpenModal_AddTask() {
      // references are now sync'd and can be accessed.
      
    }
    function closeModal_AddTask() {
      getTasksToDisplay();
      setOpenAddTaskModal(false);
    }

    const [openAddTaskTemplateModal, setOpenAddTaskTemplateModal] = React.useState(false);

    function openModal_TaskTemplate() {
      setOpenAddTaskTemplateModal(true);
    }
    function afterOpenModal_TaskTemplate() {
      // references are now sync'd and can be accessed.
      
    }
    function closeModal_TaskTemplate() {
      setOpenAddTaskTemplateModal(false);
    }
    const [openAddNewUserModal, setOpenAddNewUserModal] = React.useState(false);

    function openModal_AddNewUser() {
      setOpenAddNewUserModal(true);
    }
    function afterOpenModal_AddNewUser() {
      // references are now sync'd and can be accessed.
      
    }
    function closeModal_AddNewUser() {
      setOpenAddNewUserModal(false);
    }

    const [openDeleteUserModal, setOpenDeleteUserModal] = React.useState(false);

    function openModal_DeleteUser() {
      setOpenDeleteUserModal(true);
    }
    function afterOpenModal_DeleteUser() {
      // references are now sync'd and can be accessed.
      
    }
    function closeModal_DeleteUser() {
      setOpenDeleteUserModal(false);
    }


    return (
        <C.Container>
        <C.Header>
          <C.HeaderText>The Task Manager</C.HeaderText>
          <C.SmallHeaderText>Powered by ADS PUBLICIDADE</C.SmallHeaderText>
          <C.SmallHeaderText onClick={logout}>Sair</C.SmallHeaderText>
        </C.Header>
        <C.Body>
          <C.TasksArea>
          {Cookies.get('is_admin') === "1" && 
          <>
            <C.SubMenuArea>
              <C.ButtonDefault onClick={openModal_AddTask}>Adicionar nova tarefa</C.ButtonDefault>
              <C.ButtonSuccess onClick={openModal_TaskTemplate}>Adicionar um novo template</C.ButtonSuccess>
              {/* <C.ButtonWhite > Busca detalhada <FaSearch/></C.ButtonWhite> */}
              <C.ButtonDefault onClick={openModal_AddNewUser}>Adicionar novo usuário</C.ButtonDefault>
              <C.ButtonDanger onClick={openModal_DeleteUser}>Deletar um usuário</C.ButtonDanger>
            </C.SubMenuArea>
            <DetailSearch display={true} updateTable={updateTableWithSearchParams}/>
            </>
            
          }
          <C.UserInformations>
            
          {userInformations}

          </C.UserInformations>
          <C.TasksList>
            <C.TaskHeader>
              {isAdmin
              ?<C.TableHeadTr>
              <C.TableHeadTd scope='col'>Ação</C.TableHeadTd>
              <C.TableHeadTd scope='col'>Nome do funcionário</C.TableHeadTd>
              <C.TableHeadTd scope='col'>Título da tarefa</C.TableHeadTd>
              <C.TableHeadTd scope='col'>Esperado para terminar</C.TableHeadTd>
              <C.TableHeadTd scope='col'>Data de início</C.TableHeadTd>
              <C.TableHeadTd scope='col'>Data de término</C.TableHeadTd>
              <C.TableHeadTd scope='col'>Status</C.TableHeadTd>
              <C.TableHeadTd scope='col'>Deletar</C.TableHeadTd>

            </C.TableHeadTr>

            :<C.TableHeadTr>

            <C.TableHeadTd scope='col'>Descrição da tarefa</C.TableHeadTd>
            <C.TableHeadTd scope='col'>Horário de início</C.TableHeadTd>
            <C.TableHeadTd scope='col'>Horário de término</C.TableHeadTd>
            <C.TableHeadTd scope='col'>Status</C.TableHeadTd>
          </C.TableHeadTr>
            
            }
            </C.TaskHeader>
           <C.TasksTable>
             { taskTableItems }

           </C.TasksTable>
          </C.TasksList>
          </C.TasksArea>
          <Modal
          isOpen={openAddTaskModal}
          onAfterOpen={afterOpenModal_AddTask}
          onRequestClose={closeModal_AddTask}
          style={customStyles}
          ariaHideApp={false}
          contentLabel="Add new task"
        >
          <AddTask updateTable={getTasksToDisplay}/>
        
        </Modal>

        <Modal
          isOpen={openAddTaskTemplateModal}
          onAfterOpen={afterOpenModal_TaskTemplate}
          onRequestClose={closeModal_TaskTemplate}
          style={customStyles}
          ariaHideApp={false}
          contentLabel="Add new task"
        >
          <AddTaskTemplate/>
        
        </Modal>

        <Modal
          isOpen={openAddNewUserModal}
          onAfterOpen={afterOpenModal_AddNewUser}
          onRequestClose={closeModal_AddNewUser}
          style={customStyles}
          ariaHideApp={false}
          contentLabel="Add new task"
        >
          <AddNewUser/>
        
        </Modal>

        <Modal
          isOpen={openDeleteUserModal}
          onAfterOpen={afterOpenModal_DeleteUser}
          onRequestClose={closeModal_DeleteUser}
          style={customStyles}
          ariaHideApp={false}
          contentLabel="Delete User"
        >
          <DeleteUser/>
        
        </Modal>
        </C.Body>
      </C.Container>
    );
}

export default Page