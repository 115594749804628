import React, {useState, useEffect} from 'react';
import Cookies from 'js-cookie';
import { DestroyUser } from '../../helpers/AxiosHandler';

import * as T from './DeleteUser.style';
//worker: string, tasktitle: string, taskdescription: string, taskinit: Date, taskend: Date, taskattachment: string
const DeleteUser = (props: any) =>{
    const [messageHandle, setMessageHandle] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const [addBy, setAddBy] = useState(0);
    
    
    useEffect(()=>{
        if(Cookies.get('id')) setAddBy(Number(Cookies.get('id')))

    },[]);



    async function handleDeleteUser(){

        if(!userEmail) return setMessageHandle('Por favor, digite um email!');

        async function sendRequest(){
            const response = await DestroyUser(userEmail, addBy);

            if(response.error) return setMessageHandle('Erro ao deletar usário, verifique se o email esta correto.');
            
            
            return setMessageHandle('Usuário deletado com sucesso!');
            
        }

        sendRequest();
  
    }



 return(
     <>
    <h1>Digite o e-mail do úsuario para deletar</h1>
    <hr />
    <T.UserForm >

        <T.Input type="email" placeholder="E-mail" onChange={e=>setUserEmail(e.target.value)} value={userEmail}/>
        <small>Tenha certeza desta ação, ela nao pode ser desfeita.</small>
        <T.ButtonDanger onClick={handleDeleteUser}>Deletar usuário</T.ButtonDanger>
        <T.MessageHandling>{messageHandle}</T.MessageHandling>
    </T.UserForm>
    </>
 )
}

export default DeleteUser;