import styled from 'styled-components';

export const UserForm = styled.div`
    display: flex;
    flex-direction: column;
`

export const Input = styled.input`
    margin:10px 0 10px 0;
    font-family: Arial, Helvetica, sans-serif;
    width: auto;
    height: 25px;
    outline: none;
    border: 1px solid #ccc;
    padding-left: 10px;

`
export const Description = styled.textarea`
    font-family: Arial, Helvetica, sans-serif;
    outline: none;
    border: 1px solid #ccc;
    padding-left: 10px;

`

export const MessageHandling = styled.span`
    font-family: Arial, Helvetica, sans-serif;
    font-size: 13px;
    color: #000;

`



export const ButtonSuccess = styled.button`
width: 220px;
height: 35px;
font-size: 14px;
color: #DDEFEF;
font-weight: 600;
border: none;
border-radius: 3px;
background-color: #20bf6b;
margin: 5px;
&:hover{
    cursor: pointer;
    background-color: #26de81;
    -webkit-transition: background-color 350ms linear;
    -ms-transition: background-color 350ms linear;
    transition: background-color 350ms linear;
}

`
export const ButtonWhite = styled.button`

width: 220px;
height: 35px;
font-size: 14px;
color: #4b6584;
font-weight: 600;
border: none;
border-radius: 3px;
background-color: #d1d8e0;
margin: 5px;
&:hover{
    cursor: pointer;
    background-color: #a5b1c2;
    -webkit-transition: background-color 350ms linear;
    -ms-transition: background-color 350ms linear;
    transition: background-color 350ms linear;
}

`