import React, {useState, useEffect } from 'react';
import Modal from 'react-modal';
import dayjs from 'dayjs';
import {FaTrash} from 'react-icons/fa'
import {DeleteTask ,FindFindUploadByUploadId,FindUserDetailById} from '../../helpers/AxiosHandler'


import * as T from './Table.style';
//worker: string, tasktitle: string, taskdescription: string, taskinit: Date, taskend: Date, taskattachment: string

const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };





const TaskItem = (props: any) =>{
    const [openHandleTaskModal, setOpenHandleTaskModal] = useState(false);
    const [startedTask, setStartedTask] = useState(false);
    const [attachmentPublicPath, setAttachmentPublicPath] = useState('');
    const [statusDescribe, setStatusDescribe] = useState('');
    const [statusDescribeColor, setStatusDescribeColor] = useState('');
    const [deleteStatus, setDeleteStatus] = useState('');
    const [workerName, setWorkerName] = useState('');


    useEffect(()=>{
        switch (props.status){
            case 0:
                setStatusDescribe('Não iniciada');
                setStatusDescribeColor('#000');
                break;
            case 1:
                setStatusDescribe('Iniciada');
                setStatusDescribeColor('#20bf6b');
                break;
            case 2:
                setStatusDescribe('Concluída sem problemas');
                setStatusDescribeColor('#20bf6b');
                break;
            case 3:
                setStatusDescribe('Concluída com problemas');
                setStatusDescribeColor('#eb3b5a');
                break;
            case 4: 
                setStatusDescribe('Não foi possivel concluir');
                setStatusDescribeColor('#eb3b5a');
                break;
            default:
                setStatusDescribe('Não concluida');
                setStatusDescribeColor('#eb3b5a');
                break;
            
        }
        async function exec(){
            const response =  await FindUserDetailById(props.worker);
            setWorkerName(response.name);
        }
        exec();

    },[])

  function openModal_HandleTask() {
    setOpenHandleTaskModal(true);
  }
  function afterOpenModal_HandleTask() {
    displayTaskAttachmentPublicPath(props.taskattachment);
    
  }
  function closeModal_HandleTask() {
    setOpenHandleTaskModal(false);
  }

  async function displayTaskAttachmentPublicPath(uploadId: number){
    const response: any = await FindFindUploadByUploadId(uploadId);
    setAttachmentPublicPath(response.data.public_path);
}


async function handleDeleteTask(){
    setDeleteStatus('Deleting...')
    const response = await DeleteTask(props.id);
    if(!response.error){
      setDeleteStatus('Deleted!')
      document.getElementById(props.id)!.style.display = "none";
    }else{
      setDeleteStatus('Delete error!')
    }
}

 return(
    <T.TableLine id={props.id}>
        {/* <T.TableContent>{props.tasktitle}</T.TableContent> */}
        <T.TableContent data-id={props.id} onClick={openModal_HandleTask} style={{cursor: 'pointer'}}><strong>Clique para detalhes</strong></T.TableContent>
        <T.TableContent scope='row'>{workerName}</T.TableContent>
        <T.TableContent scope='row'>{props.tasktitle}</T.TableContent>
        <T.TableContent>{dayjs(props.expecttoend).format('DD/MM/YYYY')}</T.TableContent>
        <T.TableContent>{props.taskinit?dayjs(props.taskinit).format('DD/MM/YYYY HH:mm:ss'):''}</T.TableContent>
        <T.TableContent>{props.taskinit?dayjs(props.taskend).format('DD/MM/YYYY HH:mm:ss'):''}</T.TableContent>
        <T.TableContent style={{color: statusDescribeColor}}>{statusDescribe}</T.TableContent>
        <T.TableContent><FaTrash color='#fc5c65' cursor={'pointer'} onClick={handleDeleteTask}/> {deleteStatus} </T.TableContent>
        {/* <T.TableContent>{props.taskattachment}</T.TableContent> */}
        
        <Modal
        isOpen={openHandleTaskModal}
        onAfterOpen={afterOpenModal_HandleTask}
        onRequestClose={closeModal_HandleTask}
        style={customStyles}
        ariaHideApp={false}
        contentLabel="Add new task"
      >


        <T.TaskDetailBox style={{display: startedTask?'none':'block'}}>
            {props.status == 0 &&
              <div>
                <p>O usuário ainda não iniciou esta tarefa.</p>
                {/* <small>Horário de início: {dayjs(props.taskinit).format('DD/MM/YYYY HH:mm:ss')}</small> */}
              </div>
            }

            {props.status == 1 &&
              <div>
                <p>O usuário já iniciou esta tarefa, mas ainda não concluiu.</p>
                <small>Horário de início: {dayjs(props.taskinit).format('DD/MM/YYYY HH:mm:ss')}</small>
              </div>
            }

            {props.status == 2 &&
              <div style={{display: 'flex', flexDirection: 'column'}}>
                <p style={{color: '#20bf6b'}}>O usuário já concluiu a tarefa e não houve nenhum problema reportado por ele.</p>
                <small>Horário de início: {dayjs(props.taskinit).format('DD/MM/YYYY HH:mm:ss')}</small>
                <small>Horário de término: {dayjs(props.taskend).format('DD/MM/YYYY HH:mm:ss')}</small>
                <small>O usúario demorou <strong>{dayjs(props.taskend).diff(dayjs(props.taskinit), 'minute')}</strong> minutos para concluir </small>
              </div>
            }

            {props.status >= 3 &&
              <div style={{display: 'flex', flexDirection: 'column'}}>
                <p style={{color: '#eb3b5a'}}>O usuário já concluiu a tarefa porém foram reportados alguns problemas.</p>
                <small>Horário de início: {dayjs(props.taskinit).format('DD/MM/YYYY HH:mm:ss')}</small>
                <small>Horário de término: {dayjs(props.taskend).format('DD/MM/YYYY HH:mm:ss')}</small>
                <small>O usúario demorou <strong>{dayjs(props.taskend).diff(dayjs(props.taskinit), 'minute')}</strong> minutos para concluir </small>
                <p>Você pode conferir o relatório do usuário abaixo das informações da tarefa.</p>
              </div>
            }
            <hr />
            <h3>Titulo</h3>
            <p>{props.tasktitle}</p>
            <h3>Descrição</h3>
            <T.Description rows={7} readOnly={true}>{props.taskdescription}</T.Description>
            <h5>Link da imagem</h5>
            <small style={{fontSize: '10px'}}>
              <a href={attachmentPublicPath} target={'_blank'} style={{textDecoration: 'none'}}>{attachmentPublicPath}</a>
            </small>
            <hr />
            {props.status >= 3 &&
              <div>
                <h3>Report do usuário</h3>
                <T.Description rows={7} readOnly={true}>{props.taskreport}</T.Description>
              </div>
            }
        </T.TaskDetailBox>

      </Modal>
    </T.TableLine>
 )
}

export default TaskItem;