import {AxiosValidateToken} from '../helpers/AxiosHandler';
import Cookies from 'js-cookie';
import {Navigate} from 'react-router-dom';



type Props = {
 children: JSX.Element
}
interface TokenCookie{
    jwt: string,
    id: number,
    is_admin: number, 
    exp: number
}
export const RequireAuth = ({children}: Props)=>{
    //checking 
    let auth = false;
    const token = Cookies.get('token');
    if(!token) auth = false;
    if(token){
        // let jwtDecoded = jwt.decode(token, {complete: true});
        
        const timestampNow = new Date().getTime();
        console.log(Math.round(Number(timestampNow) / 1000));
        console.log(Cookies.get('exp'))
        if(Math.round(Number(timestampNow) / 1000) < Number(Cookies.get('exp'))){
            auth = true;
        }else{ 
            auth = false;
        }
    }

    if(auth){
        return children
    }else{
        return <Navigate to="/login"/>
    }
}