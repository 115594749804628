import React, {useState, useEffect, ChangeEvent} from 'react';
import Cookies from 'js-cookie';
import dayjs from 'dayjs';
import { AddNewUser } from '../../helpers/AxiosHandler';

import * as T from './AddNewUser.style';
//worker: string, tasktitle: string, taskdescription: string, taskinit: Date, taskend: Date, taskattachment: string
const AddTask = (props: any) =>{

    const [addBy, setAddBy ] = useState<number>(0);
    const [messageHandle, setMessageHandle] = useState('');
    const [userName, setUserName] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const [userPassword, setUserPassword] = useState('');
    const [userPasswordConfirm, setUserPasswordConfirm] = useState('');
    
    
    useEffect(()=>{
        if(Cookies.get('id')) setAddBy(Number(Cookies.get('id')))
        // async function execute(){
        //     const response = await GetAllUsersRef(Number(Cookies.get('id')));
        //     const items = response.map((property, key)=>
        //         <option key={key} value={property.id}>{property.name}</option>
        //     )
            

        //     const responseTemplate = await GetAllUserTemplates(Number(Cookies.get('id')));
        //     const templates = responseTemplate.data;
        //     const itemsTemplate = templates.map((property: {id: number, task_title: string}, key)=>
        //         <option key={key} value={property.id}>{property.task_title}</option>
        //     )
        //     setUsers(items as any);
        //     setTaskTemplate(itemsTemplate as any);
        // }
        // execute()

    },[]);



    async function handleCreateUser(){

        if(userPassword == userPasswordConfirm){
            async function executeRequest(){
                const response = await AddNewUser(userName, userEmail, userPassword, addBy, 0)
                if(response.error == false){
                    setMessageHandle('Usuário adicionado com sucesso');
                }else{
                    setMessageHandle('Não foi possivel adicionar o usuário')
                }
                setTimeout(()=>{
                    setUserName('')
                    setUserEmail('')
                    setUserPassword('')
                    setUserPasswordConfirm('')
                    setMessageHandle('');
                },1500)
            }
            await executeRequest();
        }else{
            setMessageHandle('As senhas nao coincidem');
        }
    }



 return(
     <>
    <h1>Adicionar um novo usuário</h1>
    <hr />
    <T.UserForm >

        <T.Input type="text" placeholder="Nome" onChange={e=>setUserName(e.target.value)} value={userName}/>
        <T.Input type="text" placeholder="E-mail" onChange={e=>setUserEmail(e.target.value)} value={userEmail}/>
        <T.Input type="password" placeholder="Senha" onChange={e=>setUserPassword(e.target.value)} value={userPassword}/>
        <T.Input type="password" placeholder="Confirme a senha" onChange={e=>setUserPasswordConfirm(e.target.value)} value={userPasswordConfirm}/>
        <T.ButtonSuccess onClick={handleCreateUser}>Adicionar usuário</T.ButtonSuccess>
        <T.MessageHandling>{messageHandle}</T.MessageHandling>
    </T.UserForm>
    </>
 )
}

export default AddTask;