import styled from 'styled-components';

export const Container = styled.div`

`;
export const Header = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #334355;
    padding: 30px;
    color: #fff;
    height: auto;
`;
export const HeaderText = styled.h2`
`;
export const SmallHeaderText = styled.small`
`;
export const Body = styled.div`
`;
export const Menu = styled.div`
`;
export const MenuUl = styled.ul`
`;
export const MenuLi = styled.li`
`;
export const TasksArea = styled.div`

display: flex;
flex-direction: column;
align-items: center;
margin-top: 35px;

`;
export const SubMenuArea = styled.div`

display: flex;
flex-direction: row;
align-items: center;
margin-top: 35px;

`;
export const TasksList = styled.table`
border: solid 1px #DDEEEE;
border-collapse: collapse;
border-spacing: 0;
font: normal 13px Arial, sans-serif;
`;

export const TaskHeader = styled.thead`

padding: 15px;

`;

export const TableHeadTr = styled.tr`
border: 1px solid #CCC;
text-align: center;

`;
export const TableHeadTd = styled.th`
background-color: #DDEFEF;
border: solid 1px #DDEEEE;
color: #336B6B;
padding: 10px;
text-align: left;
text-shadow: 1px 1px 1px #fff;
`;

export const TasksTable = styled.tbody`
padding: 15px;

`;

export const ButtonDefault = styled.button`
width: 220px;
height: 35px;
font-size: 14px;
color: #DDEFEF;
font-weight: 600;
border: none;
border-radius: 3px;
background-color: #3867d6;
margin: 5px;
&:hover{
    cursor: pointer;
    background-color: #4b7bec;
    -webkit-transition: background-color 350ms linear;
    -ms-transition: background-color 350ms linear;
    transition: background-color 350ms linear;
}

`

export const ButtonSuccess = styled.button`
width: 220px;
height: 35px;
font-size: 14px;
color: #DDEFEF;
font-weight: 600;
border: none;
border-radius: 3px;
background-color: #20bf6b;
margin: 5px;
&:hover{
    cursor: pointer;
    background-color: #26de81;
    -webkit-transition: background-color 350ms linear;
    -ms-transition: background-color 350ms linear;
    transition: background-color 350ms linear;
}

`
export const ButtonWhite = styled.button`

width: 220px;
height: 35px;
font-size: 14px;
color: #4b6584;
font-weight: 600;
border: none;
border-radius: 3px;
background-color: #d1d8e0;
margin: 5px;
&:hover{
    cursor: pointer;
    background-color: #a5b1c2;
    -webkit-transition: background-color 350ms linear;
    -ms-transition: background-color 350ms linear;
    transition: background-color 350ms linear;
}

`

export const ButtonDanger = styled.button`

width: 220px;
height: 35px;
font-size: 14px;
color: #ecf0f1;
font-weight: 600;
border: none;
border-radius: 3px;
background-color: #e74c3c;
margin: 5px;
&:hover{
    cursor: pointer;
    background-color: #c0392b;
    -webkit-transition: background-color 350ms linear;
    -ms-transition: background-color 350ms linear;
    transition: background-color 350ms linear;
}

`

export const UserInformations = styled.div`
max-width:880px;
display: flex;
flex-direction: row;
align-items: center;
flex-wrap: wrap;
`

export const UserInformationsBox = styled.div`
border: 1px solid rgba(223, 230, 233,1.0);
border-radius: 3px;
width: 200px;
padding: 10px;
box-shadow: 2px 2px 2px rgba(45, 52, 54,0.20);
margin: 10px;


`
export const UserInformationsTitle = styled.div`
padding: 8px;
font-weight: 600;
border-bottom: 1px solid #CCC;
`
export const UserInformationsContent = styled.div`
display: flex;
flex-direction: row;
justify-content: space-around;
`
export const UserInformationsItem = styled.span`
padding: 5px;
font-weight: 600;
`
